<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Pricing</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Pricing</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Pricing Area -->
<section class="pricing-area ptb-110">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-pricing-box">
                    <div class="pricing-header">
                        <h3>Free</h3>
                        <p>Get your business up <br>and running</p>
                    </div>

                    <div class="price">
                        $0 <span>/m</span>
                    </div>

                    <div class="buy-btn">
                        <a href="#" class="btn btn-primary">Get Started Free</a>
                    </div>

                    <ul class="pricing-features">
                        <li><i class='bx bx-check'></i> Drag & Drop Builder</li>
                        <li><i class='bx bx-check'></i> Lead Generation & Sales</li>
                        <li><i class='bx bx-check'></i> Boot & Digital Assistants</li>
                        <li><i class='bx bx-check'></i> Customer Service</li>
                        <li><i class='bx bx-check'></i> Up to 1000 Subscribers</li>
                        <li><i class='bx bx-check'></i> Unlimited Broadcasts</li>
                        <li><i class='bx bx-check'></i> Landing Pages & Web Widgets</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-pricing-box">
                    <div class="pricing-header">
                        <h3>Pro</h3>
                        <p>Get your business up <br>and running</p>
                    </div>

                    <div class="price">
                        $149 <span>/m</span>
                    </div>

                    <div class="buy-btn">
                        <a href="#" class="btn btn-primary">Get Started Free</a>
                    </div>

                    <ul class="pricing-features">
                        <li><i class='bx bx-check'></i> Drag & Drop Builder</li>
                        <li><i class='bx bx-check'></i> Lead Generation & Sales</li>
                        <li><i class='bx bx-check'></i> Boot & Digital Assistants</li>
                        <li><i class='bx bx-check'></i> Customer Service</li>
                        <li><i class='bx bx-check'></i> Up to 3300 Subscribers</li>
                        <li><i class='bx bx-check'></i> Unlimited Broadcasts</li>
                        <li><i class='bx bx-check'></i> Landing Pages & Web Widgets</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-pricing-box">
                    <div class="pricing-header">
                        <h3>Premium</h3>
                        <p>Get your business up <br>and running</p>
                    </div>

                    <div class="price">
                        $179 <span>/m</span>
                    </div>

                    <div class="buy-btn">
                        <a href="#" class="btn btn-primary">Get Started Free</a>
                    </div>

                    <ul class="pricing-features">
                        <li><i class='bx bx-check'></i> Drag & Drop Builder</li>
                        <li><i class='bx bx-check'></i> Lead Generation & Sales</li>
                        <li><i class='bx bx-check'></i> Boot & Digital Assistants</li>
                        <li><i class='bx bx-check'></i> Customer Service</li>
                        <li><i class='bx bx-check'></i> Up to 10000 Subscribers</li>
                        <li><i class='bx bx-check'></i> Unlimited Broadcasts</li>
                        <li><i class='bx bx-check'></i> Landing Pages & Web Widgets</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Pricing Area -->

<!-- Start Pricing Area -->
<section class="pricing-area pt-0 ptb-110">
    <div class="container">
        <div class="section-title">
            <h2>Our Pricing Plan</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="tab pricing-tab">
            <ul class="tabs">
                <li><a href="#">
                    Monthly Plan
                </a></li>
                
                <li><a href="#">
                    Yearly Plan
                </a></li>
            </ul>

            <div class="tab_content">
                <div class="tabs_item">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-pricing-box">
                                <div class="pricing-header">
                                    <h3>Free</h3>
                                    <p>Get your business up <br>and running</p>
                                </div>

                                <div class="price">
                                    $0 <span>/m</span>
                                </div>

                                <div class="buy-btn">
                                    <a href="#" class="btn btn-primary">Get Started Free</a>
                                </div>

                                <ul class="pricing-features">
                                    <li><i class='bx bx-check'></i> Drag & Drop Builder</li>
                                    <li><i class='bx bx-check'></i> Lead Generation & Sales</li>
                                    <li><i class='bx bx-check'></i> Boot & Digital Assistants</li>
                                    <li><i class='bx bx-check'></i> Customer Service</li>
                                    <li><i class='bx bx-check'></i> Up to 1000 Subscribers</li>
                                    <li><i class='bx bx-check'></i> Unlimited Broadcasts</li>
                                    <li><i class='bx bx-check'></i> Landing Pages & Web Widgets</li>
                                </ul>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-pricing-box">
                                <div class="pricing-header">
                                    <h3>Pro</h3>
                                    <p>Get your business up <br>and running</p>
                                </div>

                                <div class="price">
                                    $149 <span>/m</span>
                                </div>

                                <div class="buy-btn">
                                    <a href="#" class="btn btn-primary">Get Started Free</a>
                                </div>

                                <ul class="pricing-features">
                                    <li><i class='bx bx-check'></i> Drag & Drop Builder</li>
                                    <li><i class='bx bx-check'></i> Lead Generation & Sales</li>
                                    <li><i class='bx bx-check'></i> Boot & Digital Assistants</li>
                                    <li><i class='bx bx-check'></i> Customer Service</li>
                                    <li><i class='bx bx-check'></i> Up to 3300 Subscribers</li>
                                    <li><i class='bx bx-check'></i> Unlimited Broadcasts</li>
                                    <li><i class='bx bx-check'></i> Landing Pages & Web Widgets</li>
                                </ul>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                            <div class="single-pricing-box">
                                <div class="pricing-header">
                                    <h3>Premium</h3>
                                    <p>Get your business up <br>and running</p>
                                </div>

                                <div class="price">
                                    $179 <span>/m</span>
                                </div>

                                <div class="buy-btn">
                                    <a href="#" class="btn btn-primary">Get Started Free</a>
                                </div>

                                <ul class="pricing-features">
                                    <li><i class='bx bx-check'></i> Drag & Drop Builder</li>
                                    <li><i class='bx bx-check'></i> Lead Generation & Sales</li>
                                    <li><i class='bx bx-check'></i> Boot & Digital Assistants</li>
                                    <li><i class='bx bx-check'></i> Customer Service</li>
                                    <li><i class='bx bx-check'></i> Up to 10000 Subscribers</li>
                                    <li><i class='bx bx-check'></i> Unlimited Broadcasts</li>
                                    <li><i class='bx bx-check'></i> Landing Pages & Web Widgets</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-pricing-box">
                                <div class="pricing-header">
                                    <h3>Free</h3>
                                    <p>Get your business up <br>and running</p>
                                </div>

                                <div class="price">
                                    $0 <span>/y</span>
                                </div>

                                <div class="buy-btn">
                                    <a href="#" class="btn btn-primary">Get Started Free</a>
                                </div>

                                <ul class="pricing-features">
                                    <li><i class='bx bx-check'></i> Drag & Drop Builder</li>
                                    <li><i class='bx bx-check'></i> Lead Generation & Sales</li>
                                    <li><i class='bx bx-check'></i> Boot & Digital Assistants</li>
                                    <li><i class='bx bx-check'></i> Customer Service</li>
                                    <li><i class='bx bx-check'></i> Up to 1000 Subscribers</li>
                                    <li><i class='bx bx-check'></i> Unlimited Broadcasts</li>
                                    <li><i class='bx bx-check'></i> Landing Pages & Web Widgets</li>
                                </ul>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-pricing-box">
                                <div class="pricing-header">
                                    <h3>Pro</h3>
                                    <p>Get your business up <br>and running</p>
                                </div>

                                <div class="price">
                                    $249 <span>/y</span>
                                </div>

                                <div class="buy-btn">
                                    <a href="#" class="btn btn-primary">Get Started Free</a>
                                </div>

                                <ul class="pricing-features">
                                    <li><i class='bx bx-check'></i> Drag & Drop Builder</li>
                                    <li><i class='bx bx-check'></i> Lead Generation & Sales</li>
                                    <li><i class='bx bx-check'></i> Boot & Digital Assistants</li>
                                    <li><i class='bx bx-check'></i> Customer Service</li>
                                    <li><i class='bx bx-check'></i> Up to 3300 Subscribers</li>
                                    <li><i class='bx bx-check'></i> Unlimited Broadcasts</li>
                                    <li><i class='bx bx-check'></i> Landing Pages & Web Widgets</li>
                                </ul>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                            <div class="single-pricing-box">
                                <div class="pricing-header">
                                    <h3>Premium</h3>
                                    <p>Get your business up <br>and running</p>
                                </div>

                                <div class="price">
                                    $279 <span>/y</span>
                                </div>

                                <div class="buy-btn">
                                    <a href="#" class="btn btn-primary">Get Started Free</a>
                                </div>

                                <ul class="pricing-features">
                                    <li><i class='bx bx-check'></i> Drag & Drop Builder</li>
                                    <li><i class='bx bx-check'></i> Lead Generation & Sales</li>
                                    <li><i class='bx bx-check'></i> Boot & Digital Assistants</li>
                                    <li><i class='bx bx-check'></i> Customer Service</li>
                                    <li><i class='bx bx-check'></i> Up to 10000 Subscribers</li>
                                    <li><i class='bx bx-check'></i> Unlimited Broadcasts</li>
                                    <li><i class='bx bx-check'></i> Landing Pages & Web Widgets</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Pricing Area -->