<!-- Start Feedback Area -->
<section class="feedback-area">
    <div class="container">
        <div class="section-title">
            <h2>Trusted by over 250,000 business owners</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="feedback-slides owl-carousel owl-theme">
                <div class="single-feedback-item">
                    <div class="feedback-desc">
                        <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo.</p>
                    </div>

                    <div class="client-info">
                        <img src="assets/img/author1.jpg" alt="image">
                        <h3>David Luis</h3>
                        <span>Founder & CEO</span>
                    </div>
                </div>

                <div class="single-feedback-item">
                    <div class="feedback-desc">
                        <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo.</p>
                    </div>

                    <div class="client-info">
                        <img src="assets/img/author2.jpg" alt="image">
                        <h3>Steven Smith</h3>
                        <span>Developer</span>
                    </div>
                </div>

                <div class="single-feedback-item">
                    <div class="feedback-desc">
                        <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo.</p>
                    </div>

                    <div class="client-info">
                        <img src="assets/img/author3.jpg" alt="image">
                        <h3>Sarah Lucy</h3>
                        <span>Designer</span>
                    </div>
                </div>

                <div class="single-feedback-item">
                    <div class="feedback-desc">
                        <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo.</p>
                    </div>

                    <div class="client-info">
                        <img src="assets/img/author4.jpg" alt="image">
                        <h3>James Anderson</h3>
                        <span>Manager</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="feedback-shape"><img src="assets/img/white-shape2.png" alt="image"></div>
    <div class="shape-rectangle"><img src="assets/img/shape/1.png" alt="image"></div>
</section>
<!-- End Feedback Area -->
