<!-- Start FunFacts Area -->
<section class="funfacts-area ptb-110">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                <div class="single-funfacts">
                    <div class="circlechart" data-percentage="60"></div>
                    <span>Design</span>
                    <h3>Codes</h3>
                </div>
            </div>

            <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                <div class="single-funfacts">
                    <div class="circlechart" data-percentage="75"></div>
                    <span>Our happy</span>
                    <h3>Clients</h3>
                </div>
            </div>

            <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                <div class="single-funfacts">
                    <div class="circlechart" data-percentage="85"></div>
                    <span>Projects</span>
                    <h3>Design</h3>
                </div>
            </div>

            <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                <div class="single-funfacts">
                    <div class="circlechart" data-percentage="95"></div>
                    <span>Completed</span>
                    <h3>Projects</h3>
                </div>
            </div>
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>
<!-- End FunFacts Area -->
