<!-- Computer Vision Banner Area -->
<div class="computer-vision-banner-area">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="computer-vision-banner-content">
                    <h1>Customer AI & Big Data Solution for Analystics</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    
                    <ul class="banner-btn">
                        <li>
                            <a href="#" class="btn btn-primary">Get Started</a>
                        </li>
                        <li>
                            <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube">
                                <i class="fa-solid fa-play"></i>
                                Watch Intro Video
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="computer-vision-banner-image">
                    <img src="assets/img/computer-vision/banner/main.png" alt="image">
                </div>
            </div>
        </div>

        <div class="counter-inner-box with-white-bg-color pt-100 pb-70">
            <div class="row justify-content-center">
                <div class="col-lg-3 col-sm-6">
                    <div class="counter-card">
                        <div class="icon">
                            <i class='bx bx-check-shield'></i>
                        </div>
                        <h3>
                            <span class="odometer" data-count="2460">2460</span>
                            <span class="sign-icon">+</span>
                        </h3>
                        <p>Completed project dolor sit amet.</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="counter-card">
                        <div class="icon bg-2">
                            <i class='bx bx-user-circle'></i>
                        </div>
                        <h3>
                            <span class="odometer" data-count="1800">1800</span>
                            <span class="sign-icon">+</span>
                        </h3>
                        <p>Satisfied clients dolor sit lorem  amet.</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="counter-card">
                        <div class="icon bg-3">
                            <i class='bx bx-layer'></i>
                        </div>
                        <h3>
                            <span class="odometer" data-count="832">832</span>
                            <span class="sign-icon">+</span>
                        </h3>
                        <p>Industries served dolor sit amet.</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="counter-card">
                        <div class="icon bg-4">
                            <i class='bx bx-buildings'></i>
                        </div>
                        <h3>
                            <span class="odometer" data-count="12">12</span>
                            <span class="sign-icon">+</span>
                        </h3>
                        <p>Office brunches around the world.</p>
                    </div>
                </div>
            </div>

            <div class="counter-shape">
                <img src="assets/img/ai-solution/counter-shape.png" alt="image">
            </div>
        </div>
    </div>

    <div class="computer-vision-bg-shape">
        <img src="assets/img/computer-vision/banner/banner-bg-shape.png" alt="image">
    </div>
    <div class="computer-vision-shape-1">
        <img src="assets/img/computer-vision/banner/shape1.png" alt="image">
    </div>
    <div class="computer-vision-shape-2">
        <img src="assets/img/computer-vision/banner/shape2.png" alt="image">
    </div>
    <div class="computer-vision-shape-3">
        <img src="assets/img/computer-vision/banner/shape3.png" alt="image">
    </div>
</div>

<!-- Features Wrap Area -->
<div class="features-wrap-area pt-100 pb-70">
    <div class="container">
        <div class="section-title title-with-underline">
            <h2>Endless Possibilities With <b>AI & Big Data</b> Computer Vision</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit dolore magna aliqua.</p>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-3 col-sm-6">
                <div class="features-wrap-card">
                    <div class="features-image">
                        <img src="assets/img/artificial-intelligence/features/features-1.png" alt="image">
                    </div>
                    <div class="features-content">
                        <h3>Departments</h3>
                        <p>Lorem ipsum dolor sit am adipisc elit, sed do eiusmod. Lorem ipsum dolor sit adipiscing elit.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="features-wrap-card">
                    <div class="features-image">
                        <img src="assets/img/artificial-intelligence/features/features-2.png" alt="image">
                    </div>
                    <div class="features-content">
                        <h3>Industries</h3>
                        <p>Lorem ipsum dolor sit am adipisc elit, sed do eiusmod. Lorem ipsum dolor sit adipiscing elit.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="features-wrap-card">
                    <div class="features-image">
                        <img src="assets/img/artificial-intelligence/features/features-3.png" alt="image">
                    </div>
                    <div class="features-content">
                        <h3>Technology</h3>
                        <p>Lorem ipsum dolor sit am adipisc elit, sed do eiusmod. Lorem ipsum dolor sit adipiscing elit.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="features-wrap-card">
                    <div class="features-image">
                        <img src="assets/img/artificial-intelligence/features/features-4.png" alt="image">
                    </div>
                    <div class="features-content">
                        <h3>Business</h3>
                        <p>Lorem ipsum dolor sit am adipisc elit, sed do eiusmod. Lorem ipsum dolor sit adipiscing elit.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- AI Solution Area -->
<div class="ai-solution-area pb-100">
    <div class="container">
        <div class="row justify-content-center align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="ai-solution-wrap-image">
                    <img src="assets/img/computer-vision/solution.png" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="ai-solution-content">
                    <h3>Analysis And Deep <b>Machine Learning</b> Provider With Ai Solution</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipiscing elit nulla faucibus pulvinar iaculis et eu arcu mauris euismod duis diam nunc ultrices blandit montes quis id erat diam non rhoncus cras elit.</p>
                    <ul class="list">
                        <li><i class="fa-solid fa-check"></i> The Most Personalized Object Detection</li>
                        <li><i class="fa-solid fa-check"></i> We Create The Most Realistic AI</li>
                        <li><i class="fa-solid fa-check"></i> Innovative Machine Learning Products</li>
                    </ul>
                    <div class="solution-btn">
                        <a href="#" class="btn btn-primary">Learn More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- AI Services Area -->
<div class="ai-services-area with-linear-gradient-color ptb-100">
    <div class="container">
        <div class="section-title title-with-underline">
            <h2>See Wide Range Of Our <b>Services</b> With Artificial Intelligence</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit dolore magna aliqua.</p>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-4 col-sm-6">
                <div class="ai-services-card">
                    <div class="services-image">
                        <img src="assets/img/artificial-intelligence/services/services-1.png" alt="image">
                    </div>
                    <div class="services-content">
                        <h3>
                            <a href="#">Robotics And Drones</a>
                        </h3>
                        <p>Lorem ipsum dolor sit am adipiscing elit sed do eiusmod. Lorem ipsum dolor sit am adipiscing elit sed do eiusmod.</p>
                        <a href="#" class="services-btn">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="ai-services-card">
                    <div class="services-image">
                        <img src="assets/img/artificial-intelligence/services/services-2.png" alt="image">
                    </div>
                    <div class="services-content">
                        <h3>
                            <a href="#">Machine Learning</a>
                        </h3>
                        <p>Lorem ipsum dolor sit am adipiscing elit sed do eiusmod. Lorem ipsum dolor sit am adipiscing elit sed do eiusmod.</p>
                        <a href="#" class="services-btn">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="ai-services-card">
                    <div class="services-image">
                        <img src="assets/img/artificial-intelligence/services/services-3.png" alt="image">
                    </div>
                    <div class="services-content">
                        <h3>
                            <a href="#">Image Processing</a>
                        </h3>
                        <p>Lorem ipsum dolor sit am adipiscing elit sed do eiusmod. Lorem ipsum dolor sit am adipiscing elit sed do eiusmod.</p>
                        <a href="#" class="services-btn">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="view-all-services-btn">
                    <a href="services-1.html" class="btn btn-primary">View All Services</a>
                </div>
            </div>
        </div>
    </div>

    <div class="ai-services-shape2">
        <img src="assets/img/computer-vision/services-shape.png" alt="image">
    </div>
</div>

<!-- Team Wrap Area -->
<div class="team-wrap-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-12">
                <div class="team-section-content">
                    <h3>Our Expert <b>Team</b> Members</h3>
                    <p>Lorem ipsum dolor sit am adipisc elit sed do eiusmod. Demis dolor seit.</p>
                    <div class="team-btn">
                        <a href="team.html" class="btn btn-primary">View All Member</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-9 col-md-12">
                <div class="row justify-content-center">
                    <div class="col-lg-4 col-sm-6">
                        <div class="team-wrap-card">
                            <div class="team-image">
                                <img src="assets/img/computer-vision/team/team1.jpg" alt="image">
                            </div>
                            <div class="team-content">
                                <div class="social">
                                    <a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a>
                                    <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
                                    <a href="#" target="_blank"><i class="fab fa-instagram"></i></a>
                                </div>
                                <h3>Belkon Thomas</h3>
                                <span>CEO & Director</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6">
                        <div class="team-wrap-card">
                            <div class="team-image">
                                <img src="assets/img/computer-vision/team/team2.jpg" alt="image">
                            </div>
                            <div class="team-content">
                                <div class="social">
                                    <a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a>
                                    <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
                                    <a href="#" target="_blank"><i class="fab fa-instagram"></i></a>
                                </div>
                                <h3>Jenica Mendri</h3>
                                <span>Vice President</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6">
                        <div class="team-wrap-card">
                            <div class="team-image">
                                <img src="assets/img/computer-vision/team/team3.jpg" alt="image">
                            </div>
                            <div class="team-content">
                                <div class="social">
                                    <a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a>
                                    <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
                                    <a href="#" target="_blank"><i class="fab fa-instagram"></i></a>
                                </div>
                                <h3>Rishy Jekborn</h3>
                                <span>Managing Director</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="team-wrap-shape">
        <img src="assets/img/computer-vision/team/shape.png" alt="image">
    </div>
</div>

<!-- Handle Area -->
<div class="handle-area ptb-100">
    <div class="container">
        <div class="row justify-content-center align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="ai-handle-content">
                    <h3>Do What Matters And We'll <b>Handle</b> The Back Office Routine.</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipiscing elit nulla faucibus pulvinar iaculis et eu arcu mauris euismod duis diam nunc ultrices blandit montes quis id erat diam non rhoncus cras elit.</p>
                    
                    <div class="handle-inner-card">
                        <div class="content">
                            <div class="icon">
                                <i class='bx bx-dollar-circle'></i>
                            </div>
                            <h4>Fast Cost Saving</h4>
                            <p>Lorem ipsum dolor sit am adipisc elit sed do eiusmod. Demis dolor seit adipiscing elit.</p>
                        </div>
                    </div>

                    <div class="handle-inner-card">
                        <div class="content">
                            <div class="icon">
                                <i class='bx bx-layer'></i>
                            </div>
                            <h4>Critical Infrastructure</h4>
                            <p>Lorem ipsum dolor sit am adipisc elit sed do eiusmod. Demis dolor seit adipiscing elit.</p>
                        </div>
                    </div>

                    <div class="handle-btn">
                        <a href="#" class="btn btn-primary">Learn More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="ai-handle-wrap-image">
                    <img src="assets/img/ai-solution/handle.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Case Study Area -->
<div class="case-study-area ptb-100">
    <div class="container">
        <div class="section-title title-with-underline">
            <h2>Our Featured <b>Case Study</b> With Artificial Intelligence & ML</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit dolore magna aliqua.</p>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-3 col-sm-6">
                <div class="case-study-card">
                    <div class="case-study-image">
                        <img src="assets/img/computer-vision/case-study/case-study1.jpg" alt="image">
                    </div>
                    <div class="case-study-content">
                        <h3>
                            <a href="#">Research & Analysis</a>
                        </h3>
                        <span>Industrial Adipiscing</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="case-study-card">
                    <div class="case-study-image">
                        <img src="assets/img/computer-vision/case-study/case-study2.jpg" alt="image">
                    </div>
                    <div class="case-study-content">
                        <h3>
                            <a href="#">Big Data Analysis</a>
                        </h3>
                        <span>Industrial Adipiscing</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="case-study-card">
                    <div class="case-study-image">
                        <img src="assets/img/computer-vision/case-study/case-study3.jpg" alt="image">
                    </div>
                    <div class="case-study-content">
                        <h3>
                            <a href="#">Data Science Analysis</a>
                        </h3>
                        <span>Industrial Adipiscing</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="case-study-card">
                    <div class="case-study-image">
                        <img src="assets/img/computer-vision/case-study/case-study4.jpg" alt="image">
                    </div>
                    <div class="case-study-content">
                        <h3>
                            <a href="#">Program Development</a>
                        </h3>
                        <span>Industrial Adipiscing</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="view-all-soutions-btn">
                    <a href="#" class="btn btn-primary">View All Soutions</a>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Clients Review Area -->
<div class="clients-review-area ptb-100">
    <div class="container">
        <div class="section-title title-with-underline">
            <h2>Check what our clients <b>Feedback</b> On Our AI & Big Data Services</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit dolore magna aliqua.</p>
        </div>
        
        <div class="clients-review-slides owl-carousel owl-theme">
            <div class="clients-review-item">
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
                <p>Lorem ipsum dolor sit amet consectetur adipiscing elit dui arcu purus nec morbi pretium molestie libero quis id morbi venenatis mi lorem ipsum dolor sit amet consectetur adipiscing elit.</p>

                <div class="client-info">
                    <img src="assets/img/artificial-intelligence/user1.jpg" alt="image">
                    <h3>Thomas Adbadon</h3>
                    <p>Founder & CEO</p>
                </div>
            </div>

            <div class="clients-review-item">
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
                <p>Lorem ipsum dolor sit amet consectetur adipiscing elit dui arcu purus nec morbi pretium molestie libero quis id morbi venenatis mi lorem ipsum dolor sit amet consectetur adipiscing elit.</p>

                <div class="client-info">
                    <img src="assets/img/artificial-intelligence/user2.jpg" alt="image">
                    <h3>Steven Smith</h3>
                    <p>Developer</p>
                </div>
            </div>

            <div class="clients-review-item">
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
                <p>Lorem ipsum dolor sit amet consectetur adipiscing elit dui arcu purus nec morbi pretium molestie libero quis id morbi venenatis mi lorem ipsum dolor sit amet consectetur adipiscing elit.</p>

                <div class="client-info">
                    <img src="assets/img/artificial-intelligence/user2.jpg" alt="image">
                    <h3>Sarah Lucy</h3>
                    <p>Designer</p>
                </div>
            </div>

            <div class="clients-review-item">
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
                <p>Lorem ipsum dolor sit amet consectetur adipiscing elit dui arcu purus nec morbi pretium molestie libero quis id morbi venenatis mi lorem ipsum dolor sit amet consectetur adipiscing elit.</p>

                <div class="client-info">
                    <img src="assets/img/artificial-intelligence/user1.jpg" alt="image">
                    <h3>Thomas Adbadon</h3>
                    <p>Founder & CEO</p>
                </div>
            </div>

            <div class="clients-review-item">
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
                <p>Lorem ipsum dolor sit amet consectetur adipiscing elit dui arcu purus nec morbi pretium molestie libero quis id morbi venenatis mi lorem ipsum dolor sit amet consectetur adipiscing elit.</p>

                <div class="client-info">
                    <img src="assets/img/artificial-intelligence/user2.jpg" alt="image">
                    <h3>Steven Smith</h3>
                    <p>Developer</p>
                </div>
            </div>

            <div class="clients-review-item">
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
                <p>Lorem ipsum dolor sit amet consectetur adipiscing elit dui arcu purus nec morbi pretium molestie libero quis id morbi venenatis mi lorem ipsum dolor sit amet consectetur adipiscing elit.</p>

                <div class="client-info">
                    <img src="assets/img/artificial-intelligence/user2.jpg" alt="image">
                    <h3>Sarah Lucy</h3>
                    <p>Designer</p>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- AI Blog Area -->
<div class="ai-blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title title-with-underline">
            <h2>Latest Blog and News Don’t Miss Any <b>Updates</b> And News!</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit dolore magna aliqua.</p>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="ai-blog-card">
                    <div class="entry-thumbnail">
                        <a href="single-blog.html"><img src="assets/img/artificial-intelligence/blog/blog-1.jpg" alt="image"></a>
                    </div>
                    <div class="entry-post-content">
                        <div class="tag">
                            <a href="single-blog.html">TECHNOLOGY</a>
                        </div>
                        <h3><a href="single-blog.html">Making Peace With The Feast Or Famine Of Freelancing</a></h3>
                        <ul class="entry-meta">
                            <li>By <a href="blog-1.html">Thomas Kelbin</a></li>
                            <li>17th January</li>
                            <li>0 Comment</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="ai-blog-card">
                    <div class="entry-thumbnail">
                        <a href="single-blog.html"><img src="assets/img/artificial-intelligence/blog/blog-2.jpg" alt="image"></a>
                    </div>
                    <div class="entry-post-content">
                        <div class="tag">
                            <a href="single-blog.html">TECHNOLOGY</a>
                        </div>
                        <h3><a href="single-blog.html">I Used The Web For A Day On A 50 MB Budget</a></h3>
                        <ul class="entry-meta">
                            <li>By <a href="blog-1.html">Thomas Kelbin</a></li>
                            <li>17th January</li>
                            <li>0 Comment</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="ai-blog-card">
                    <div class="entry-thumbnail">
                        <a href="single-blog.html"><img src="assets/img/artificial-intelligence/blog/blog-3.jpg" alt="image"></a>
                    </div>
                    <div class="entry-post-content">
                        <div class="tag">
                            <a href="single-blog.html">TECHNOLOGY</a>
                        </div>
                        <h3><a href="single-blog.html">Here Are The 5 Most Telling Signs Of Micromanagement</a></h3>
                        <ul class="entry-meta">
                            <li>By <a href="blog-1.html">Thomas Kelbin</a></li>
                            <li>17th January</li>
                            <li>0 Comment</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>  
</div>

<!-- AI Subscribe Area -->
<div class="ai-subscribe-area with-padding-top-bottom-zero">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="ai-subscribe-content">
                    <h2>Get The Best Blog <b>Stories</b> Into Your Inbox!</h2>
    
                    <form class="newsletter-form" data-toggle="validator">
                        <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL" required autocomplete="off">
    
                        <button type="submit">Subscribe</button>
                        <div id="validator-newsletter" class="form-result"></div>

                        <div class="newsletter-checkbox-btn">
                            <input class="inp-cbx" id="cbx" type="checkbox">
                            <label class="cbx" for="cbx">
                                <span>
                                    <svg width="12px" height="10px" viewbox="0 0 12 10">
                                        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                    </svg>
                                </span>
                                <span>I agree submit data is being collected store</span>
                            </label>
                        </div>
                    </form>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="ai-subscribe-image">
                    <img src="assets/img/computer-vision/subscribe.png" alt="image">
                </div>
            </div>
        </div>
    </div>

    <div class="ai-subscribe-shape">
        <img src="assets/img/artificial-intelligence/subscribe-shape.png" alt="image">
    </div>
</div>

<!-- Wrap Area -->
<div class="partner-wrap-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="partner-wrap-card">
                    <a href="#">
                        <img src="assets/img/computer-vision/partner/partner-1.png" alt="image">
                    </a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="partner-wrap-card">
                    <a href="#">
                        <img src="assets/img/computer-vision/partner/partner-2.png" alt="image">
                    </a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="partner-wrap-card">
                    <a href="#">
                        <img src="assets/img/computer-vision/partner/partner-3.png" alt="image">
                    </a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="partner-wrap-card">
                    <a href="#">
                        <img src="assets/img/computer-vision/partner/partner-4.png" alt="image">
                    </a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="partner-wrap-card">
                    <a href="#">
                        <img src="assets/img/computer-vision/partner/partner-5.png" alt="image">
                    </a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="partner-wrap-card">
                    <a href="#">
                        <img src="assets/img/computer-vision/partner/partner-6.png" alt="image">
                    </a>
                </div>
            </div>
        </div>  
    </div>
</div>