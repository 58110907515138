<!-- AI Banner Area -->
<div class="ai-main-banner">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="ai-banner-content">
                    <h1>Global GPU Market For AI & Machine Learning</h1>
                    <p>GPUSPEED.NET : เป็นผู้นำตลาดในการเช่า GPU รัน AI ราคาประหยัด คุณภาพสูง สเปคแรง RXT 4090 24GB มีให้เลือกหลากหลายรุ่น ประหยัดงบ 5-6 เท่า รองรับ Deep Learning, LLM, GenAI,Quantum Computing.</p>
                    
                    <ul class="banner-btn">
                        <li>
                            <a (click)="openExternalLink()" class="btn btn-primary">Get Started</a>
                        </li>
                        <li>
                            <a href="#" class="video-btn popup-youtube">
                                <i class="fa-solid fa-play"></i>
                                Watch Intro Video
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="ai-banner-image">
                    <img src="assets/img/artificial-intelligence/banner/main.png" alt="image">
                </div>
            </div>
        </div>

        <div class="ai-banner-partner">
            <div class="row align-items-center">
                <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                    <div class="partner-card">
                        <a href="#">
                            <img src="assets/img/artificial-intelligence/partner/ubuntu.png" alt="image">
                        </a>
                    </div>
                </div>
                <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                    <div class="partner-card">
                        <a href="#">
                            <img src="assets/img/artificial-intelligence/partner/tensor-flow.png" alt="image">
                        </a>
                    </div>
                </div>
                <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                    <div class="partner-card">
                        <a href="#">
                            <img src="assets/img/artificial-intelligence/partner/pytorch.png" alt="image">
                        </a>
                    </div>
                </div>
                <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                    <div class="partner-card">
                        <a href="#">
                            <img src="assets/img/artificial-intelligence/partner/jupyter.png" alt="image">
                        </a>
                    </div>
                </div>
                <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                    <div class="partner-card">
                        <a href="#">
                            <img src="assets/img/artificial-intelligence/partner/nvidia.png" alt="image">
                        </a>
                    </div>
                </div>
                <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                    <div class="partner-card">
                        <a href="#">
                            <img src="assets/img/artificial-intelligence/partner/python.png" alt="image">
                        </a>
                    </div>
                </div>
            </div>  
        </div>
    </div>

    <div class="ai-banner-large-shape">
        <img src="assets/img/artificial-intelligence/banner/large-shape.png" alt="image">
    </div>

    <div class="ai-banner-main-shape">
        <img src="assets/img/artificial-intelligence/banner/ai-shape.png" alt="image">
    </div>

    <div class="ai-banner-shape-1">
        <img src="assets/img/artificial-intelligence/banner/shape-1.png" alt="image">
    </div>

    <div class="ai-banner-shape-2">
        <img src="assets/img/artificial-intelligence/banner/shape-2.png" alt="image">
    </div>

    <div class="ai-banner-shape-3">
        <img src="assets/img/artificial-intelligence/banner/shape-3.png" alt="image">
    </div>
</div>

<!-- Features Wrap Area -->
<div class="features-wrap-area pt-100 pb-70">
    <div class="container">
        <div class="section-title title-with-underline">
            <h2>For On-Demand GPU Speed <b> AI & Big Data </b><b> Deep Learning</b> Machine Learning</h2>
            <p>หากคุณกำลังมองหาพลังการประมวลผล GPU เราเตรียม Docker Container เพื่อรันซอฟต์แวร์ทั้งหมดที่คุณต้องการ และทำงานได้อย่างรวดเร็ว.</p>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-3 col-sm-6">
                <div class="features-wrap-card">
                    <div class="features-image">
                        <img src="assets/img/artificial-intelligence/features/features-1.png" alt="image">
                    </div>
                    <div class="features-content">
                        <h3>On-demand or Interruptible</h3>
                        <p>คุณสามารถเช่าการประมวลผลได้ทันที. โดยระบบจะคิดการระยะเวลาการใช้งานจริงที่คุณเช่า. ช่วยให้คุณประหยัดค่าใช้จ่าย 50-60% เลยทีเดียว เมื่อเทียบกับผู้ให้บริการเจ้าอื่น ๆ</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="features-wrap-card">
                    <div class="features-image">
                        <img src="assets/img/artificial-intelligence/features/features-2.png" alt="image">
                    </div>
                    <div class="features-content">
                        <h3>Cyber Security Protection,</h3>
                        <p>มั่นใจเรื่องระบบความปลอดภัยได้ โดยทางเราได้ผสมผสานเทคโนโลยีระดับโลกมาไว้ให้คุณมีระบบป้องกันการโจมตี DDOS, ระบบ Firewall , ระบบ VPN และ Proxy มั่นใจเรื่องความปลอดภัยข้อมูล</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="features-wrap-card">
                    <div class="features-image">
                        <img src="assets/img/artificial-intelligence/features/features-3.png" alt="image">
                    </div>
                    <div class="features-content">
                        <h3>GUI and CLI</h3>
                        <p>เพียงไม่กี่คลิก คุณก็สามาถเช่าเครื่องที่มีการประมวลผล GPU ได้อย่างง่ายดาย รันโปรแกรม Jupyter Lab ที่มีหน้าจอ Interface ใช้งานง่าย มีระบบ CLI รองรับการทำงานตามสภาพแวดล้อมที่เหมาะสม.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="features-wrap-card">
                    <div class="features-image">
                        <img src="assets/img/artificial-intelligence/features/features-4.png" alt="image">
                    </div>
                    <div class="features-content">
                        <h3>Save Cost & Time </h3>
                        <p>ช่วยประหยัดเวลาและลดต้นทุนในการทำงาน. ไม่จำเป็นต้องเช่าเครื่องเป็นรายเดือน ใช่งานแค่ไหน ก็จ่ายเท่านั้น สามารถเพิ่มลดจำนวนเครื่องได้อย่างง่ายดาย ไม่ยุ่งยาก ระบบไม่ตัดระบบการทำงาน Session</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- AI Solution Area -->
<div class="ai-solution-area">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-12">
                <div class="ai-solution-image">
                    <img src="assets/img/artificial-intelligence/solution/solution.png" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="ai-solution-content">
                    <h3>Analysis And Deep <b>Machine Learning</b> Provider With Ai Solution</h3>
                    <p>เลือกพลังการประมวลงานของคุณบน GPU ที่ตรงต่อความต้องการ DLPerf (Deep Learning Performance) - ทางเราได้มีการจัดทำระบบคะแนนการประมวลผลของอุปกรณ์ เพื่อให้ผู้ใช้สามารถมั่นใจได้ว่าแต่ละเครื่องมีประสิทธิภาพเป็นอย่างไร.</p>
                    <ul class="list">
                        <li><i class="fa-solid fa-check"></i> Automate and standardize the evaluation </li>
                        <li><i class="fa-solid fa-check"></i> Each cloud compute provider has their </li>
                        <li><i class="fa-solid fa-check"></i> Innovative Machine Learning Products</li>
                    </ul>
                    <div class="solution-btn">
                        <a href="#" class="btn btn-primary">Learn More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="ai-solution-shape">
        <img src="assets/img/artificial-intelligence/solution/shape.png" alt="image">
    </div>
</div>

<!-- Fun Facts Wrap Area -->
<section class="funfacts-wrap-area pt-70 pb-100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-3 col-sm-6">
                <div class="funfacts-wrap-card">
                    <div class="circlechart" data-percentage="80"></div>
                    <h3>Speed Up</h3>
                    <p>Server รองรับอินเตอร์ความเร็วสูง 1Gbp/s.</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="funfacts-wrap-card">
                    <div class="circlechart" data-percentage="60"></div>
                    <h3>Cheapest</h3>
                    <p>ช่วยคุณประหยัดงบประมาณได้ถึง 50-60 %.</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="funfacts-wrap-card">
                    <div class="circlechart" data-percentage="99.99"></div>
                    <h3>Security</h3>
                    <p>ระบบป้องกัน Firewall , DDOS , Proxy , VPN </p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="funfacts-wrap-card">
                    <div class="circlechart" data-percentage="100"></div>
                    <h3>Jupyter</h3>
                    <p>ทำงานบน jupyter notebook.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- AI Services Area -->
<div class="ai-services-area ptb-100">
    <div class="container">
        <div class="section-title title-with-underline">
            <h2>See Wide Range Of Our <b>Services</b> With Artificial Intelligence</h2>
            <p>ระบบรองรับการประมวลผลที่หลากหลาย ไม่ว่าจะเป็นการประมวลผลทางด้านภาษา (NLP) การประมวลผลภาพ (Images) การเรียนรู้เครื่อง (Machine Learning) หรือหุ่นยนต์ (Robotics) และอื่น ๆ ตามความต้องการ</p>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-4 col-sm-6">
                <div class="ai-services-card">
                    <div class="services-image">
                        <img src="assets/img/artificial-intelligence/services/services-1.png" alt="image">
                    </div>
                    <div class="services-content">
                        <h3>
                            <a href="">หุ่นยนต์ (Robotics And Drones)</a>
                        </h3>
                        <p>พัฒนาเทคโนโลยีล้ำสมัยด้วยการใช้หุ่นยนต์ในรูปแบบต่าง ๆ การจำลองสถานการณ์ การคิดคำนวนอัลกอริทึมต่าง ๆ เพื่อนำไปใช้ร่วมกับหุ่นยนต์</p>
                        <a href="" class="services-btn"> </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="ai-services-card">
                    <div class="services-image">
                        <img src="assets/img/artificial-intelligence/services/services-2.png" alt="image">
                    </div>
                    <div class="services-content">
                        <h3>
                            <a href="">Machine Learning</a>
                        </h3>
                        <p>อัลกอริธึมหลากหลายที่มีความสำคัญ รวมถึง DTree, SVM, KNN, และ Random Forests. ช่วยในการจัดการกับปัญหาและชนิดของข้อมูลที่ต่างกัน.</p>
                        <a href="" class="services-btn"></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="ai-services-card">
                    <div class="services-image">
                        <img src="assets/img/artificial-intelligence/services/services-3.png" alt="image">
                    </div>
                    <div class="services-content">
                        <h3>
                            <a href="">Image Processing & Video</a>
                        </h3>
                        <p>ระบบรู้จำรูปภาพ การแยกประเภทของรูปภาพ หรือ วีดีโอ โดยใช้เครื่องสมัยใหม่ต่าง ๆ เช่น YOLO9 ซึ่งทำให้งานของคุณมีความแม่นยำมากขึ้น.</p>
                        <a href="" class="services-btn"></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="ai-services-card">
                    <div class="services-image">
                        <img src="assets/img/artificial-intelligence/services/services-4.png" alt="image">
                    </div>
                    <div class="services-content">
                        <h3>
                            <a href="">การประมวลผลข้อความ (NLP) </a>
                        </h3>
                        <p>การผสานความสามารถของ NLP นำพานวัตกรรมด้วยอัลกอริธึมขั้นสูง เช่น NLP ด้วย BERT และ Transformer , LLaMA. นำมาสร้างระบบตอบคำถามที่ชาญฉลาด</p>
                        <a href="" class="services-btn"></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="ai-services-card">
                    <div class="services-image">
                        <img src="assets/img/artificial-intelligence/services/services-5.png" alt="image">
                    </div>
                    <div class="services-content">
                        <h3>
                            <a href="">AI & Blockchain </a>
                        </h3>
                        <p>การผสมผสานเทคโนโลยีระว่าง AI กับ Blockchain ซึ่งจะทำให้โมเดลของคุณมีความปลอดภัยมากขึ้น. รวมถึงการสามารถจ่ายค่าธรรมเนียมเป็นเหรียญ Token</p>
                        <a href="" class="services-btn"></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="ai-services-card">
                    <div class="services-image">
                        <img src="assets/img/artificial-intelligence/services/services-6.png" alt="image">
                    </div>
                    <div class="services-content">
                        <h3>
                            <a href="">Deep Learning</a>
                        </h3>
                        <p>เครื่องมือยอดนิยมอย่าง TensorFlow และ PyTorch ซึ่งเป็นหัวใจสำคัญในการสร้างโมเดลปัญญาประดิษฐ์ที่ฉลาดล้ำ. นอกจากนี้ยังมีเครื่องมืออื่นๆ เช่น Keras.</p>
                        <a href="" class="services-btn"></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="view-all-services-btn">
                    <a href="services-1.html" class="btn btn-primary"> </a>
                </div>
            </div>
        </div>
    </div>

    <div class="ai-services-shape">
        <img src="assets/img/artificial-intelligence/services/shape.png" alt="image">
    </div>
</div>

<!-- Handle Area -->
<div class="handle-area ptb-100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-12">
                <div class="ai-handle-content">
                    <h3>ข้อดีของการใช้ระบบ GPUSPEED <b>Handle</b> The Back Office Routine.</h3>
                    <p>GPUSPEED: การปฏิวัติวิธีทำงานด้วยพลัง GPU ที่สะดวกและรวดเร็ว ให้ทุกภารกิจด้านปัญญาประดิษฐ์และการเรียนรู้ของเครื่องเป็นไปอย่างไม่มีขีดจำกัด ด้วยการเข้าถึงทรัพยากรประมวลผลแบบ high-end ได้ทันที ไม่ต้องลงทุนด้านฮาร์ดแวร์ ลดต้นทุนและเพิ่มความยืดหยุ่นในการทำงาน เหมาะสมสำหรับนักพัฒนา, นักวิจัย, และองค์กรที่ต้องการประมวลผลข้อมูลขนาดใหญ่หรือการทำงานที่ต้องการความเร็วและความแม่นยำสูง.</p>
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-sm-6">
                            <ul class="list">
                                <li><i class="fa-solid fa-check"></i> มีความน่าเชื่อถือ ปลอดภัย</li>
                                <li><i class="fa-solid fa-check"></i> ควบคุมค่าใช้จ่าย สามารถลดต้นทุน</li>
                                <li><i class="fa-solid fa-check"></i> มีระบบป้องกัน Firewall , DDOS</li>
                            </ul>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <ul class="list">
                                <li><i class="fa-solid fa-check"></i> คิด่าใช้จ่ายงานตามความเป็นจริง</li>
                                <li><i class="fa-solid fa-check"></i> ทำงานบน Jupyter Notebook</li>
                                <li><i class="fa-solid fa-check"></i> Satisfaction 100%  </li>
                            </ul>
                        </div>
                    </div>
                    <div class="handle-btn">
                        <a href="#" class="btn btn-primary">Learn More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="ai-handle-image">
                    <img src="assets/img/artificial-intelligence/handle/handle.png" alt="image">
                </div>
            </div>
        </div>
    </div>

    <div class="handle-shape">
        <img src="assets/img/artificial-intelligence/handle/shape.png" alt="image">
    </div>
</div>

<!-- Clients Review Area -->
<div class="clients-review-area ptb-100">
    <div class="container">
        <div class="section-title title-with-underline">
            <h2>รีวิวจากลูกค้าผู้ใช้งานจริง <b>Successful</b> Projects Clients Review</h2>
            <p>ทุกเสียงจากลูกค้าที่ใช้บริการ คือหัวใจของงานเรา เพื่อจะพัฒนาปรับปรุงระบบให้ดียิ่งขึ้นไป ขอบพระคุณทุก ๆ ท่าน ที่เข้ามาใช้บริการเช่า GPUSPEED.NET ออนไลน์จากเรา.</p>
        </div>
        
        <div class="clients-review-slides owl-carousel owl-theme">
            <div class="clients-review-item">
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
                <p>ไม่น่าเชื่อ...ว่าในประเทศไทยจะมีระบบแบบนี้ด้วย ปกติเคยเห็นแต่ในต่างประเทศ. สะดวก เร็ว ค่าบริการยังไม่แพง เยี่ยมมากครับ</p>

                <div class="client-info">
                    <img src="assets/img/artificial-intelligence/user1.jpg" alt="image">
                    <h3>เอกรัตน์</h3>
                    <p>ผู้บริหาร Founder & CEO</p>
                </div>
            </div>

            <div class="clients-review-item">
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
                <p>ระบบใช้งานง่าย เพียงไม่กี่คลิกก็สามารถเข้าใช้งานได้ ไม่ยุ่งยาก ชอบๆ ไม่ต้องไปซื้อเครื่องคอมใหม่ ใช้แบบนี้รันงานสะดวกราคาถูก.</p>

                <div class="client-info">
                    <img src="assets/img/artificial-intelligence/user2.jpg" alt="image">
                    <h3>มณีรัตน์</h3>
                    <p>นักพัฒนา Developer</p>
                </div>
            </div>

            <div class="clients-review-item">
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
                <p>ทดลองใช้งานแล้ว โดยส่วนตัวรู้สึกชอบ มีเครื่องสเปคให้เลือกหลายหลาย เหมาะกับความต้องการ แถมยังราคาไม่แพง แบบนี้ใช่เลยครับ.</p>

                <div class="client-info">
                    <img src="assets/img/artificial-intelligence/user2.jpg" alt="image">
                    <h3>คุณหฤทัย </h3>
                    <p>นักออกแบบ Designer</p>
                </div>
            </div>

            <div class="clients-review-item">
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
                <p>การใช้งานเหมาะสำหรับนักพัฒนาที่ไม่ต้องไปเช่า GPU รายเดือนแพง ๆ  หรือบริการของ Google แถมราคาจับต้องได้.</p>

                <div class="client-info">
                    <img src="assets/img/artificial-intelligence/user1.jpg" alt="image">
                    <h3>คุณธงขัย </h3>
                    <p>สายงาน AI & Computer Vision </p>
                </div>
            </div>

            <div class="clients-review-item">
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
                <p>อยากจะรันงานที่ไม่ต้องตัด Session เหมือนใน Google Colab ใช่เลยแบบนี้ครับ รันงาน ยาว ๆ ไป แถมราคารับได้ โอเคเลยครับ.</p>

                <div class="client-info">
                    <img src="assets/img/artificial-intelligence/user2.jpg" alt="image">
                    <h3>คุณแพรวดาว</h3>
                    <p>สายงาน Developer</p>
                </div>
            </div>

            <div class="clients-review-item">
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
                <p>เพึ่งเห็นว่าในประเทศไทยมีระบบปล่อยให้เช่า GPU ออนไลน์แบบนี้ด้วย ดีจัง...ต่อไปไม่ต้องไปใช้บริการของต่างประเทศแล้ว.</p>

                <div class="client-info">
                    <img src="assets/img/artificial-intelligence/user2.jpg" alt="image">
                    <h3>คุณน้ำฝน</h3>
                    <p>สายงาน Computer Vision</p>
                </div>
            </div>
        </div>
    </div>
</div>