<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>About Us</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>About Us</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start About Area -->
<section class="about-area ptb-110">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span>About Us</span>
                    <h2>We've Been Thriving in 37 Years The Tech Area</h2>
                    <p>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed viverra nunc tinci dunt nec elei fend et tiram.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sagittis egestas ante, sed viverra nunc tincidunt nec nteger nonsed condimntum elit, sit amet feugiat lorem. Proin tempus sagittis velit vitae scelerisque.</p>
                    <p>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed viverra nunc tinci dunt nec elei fend et tiram.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/3.png" alt="image">
                </div>
            </div>
        </div>

        <div class="about-inner-area">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>Our History</h3>
                        <p>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed viverra nunc tinci dunt nec elei fend et tiram.</p>

                        <i class="flaticon-house"></i>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>Our Mission</h3>
                        <p>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed viverra nunc tinci dunt nec elei fend et tiram.</p>

                        <i class="flaticon-insurance"></i>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                    <div class="about-text">
                        <h3>Who we are</h3>
                        <p>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed viverra nunc tinci dunt nec elei fend et tiram.</p>

                        <i class="flaticon-box"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End About Area -->

<!-- Start Features Area -->
<section class="features-area ptb-110 bg-f7f9fc">
    <div class="container">
        <div class="overview-box">
            <div class="image wow zoomIn" data-wow-delay="1s">
                <img src="assets/img/1.png" alt="image">
            </div>

            <div class="content">
                <h3>Make your business more competitive and sustainable.</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                <ul class="features-list">
                    <li><span><i class='bx bx-check-double'></i> Influencing Sales</span></li>
                    <li><span><i class='bx bx-check-double'></i> Generating Insights</span></li>
                    <li><span><i class='bx bx-check-double'></i> Engaging Customer</span></li>
                    <li><span><i class='bx bx-check-double'></i> Boosting Operations</span></li>
                </ul>
            </div>
        </div>

        <div class="overview-box">
            <div class="content">
                <h3>Do what matters and we'll handle the back office routine.</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                <ul class="features-list">
                    <li><span><i class='bx bx-check-double'></i> Fully Support</span></li>
                    <li><span><i class='bx bx-check-double'></i> Reliable & Cost Effective</span></li>
                    <li><span><i class='bx bx-check-double'></i> Dedicated Developers</span></li>
                    <li><span><i class='bx bx-check-double'></i> 100% Customer Satisfaction</span></li>
                </ul>
            </div>

            <div class="image wow zoomIn" data-wow-delay="1s">
                <img src="assets/img/2.png" alt="image">
            </div>
        </div>
    </div>

    <div class="rectangle-shape1"><img src="assets/img/rectangle-shape1.png" alt="image"></div>
    <div class="rectangle-shape2"><img src="assets/img/rectangle-shape2.png" alt="image"></div>
</section>
<!-- End Features Area -->

<app-funfact></app-funfact>

<!-- Start Team Area -->
<section class="team-area bg-fafafa ptb-110 pb-0">
    <div class="container">
        <div class="section-title">
            <h2>Meet Our Team</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team/1.jpg" alt="team">

                        <div class="social">
                            <a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-instagram"></i></a>
                        </div>
                    </div>

                    <div class="content">
                        <h3>John Smith</h3>
                        <span>Web Developer</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team/2.jpg" alt="team">

                        <div class="social">
                            <a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-instagram"></i></a>
                        </div>
                    </div>

                    <div class="content">
                        <h3>Lucy Eva</h3>
                        <span>Manager</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team/3.jpg" alt="team">

                        <div class="social">
                            <a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-instagram"></i></a>
                        </div>
                    </div>

                    <div class="content">
                        <h3>Steven Smith</h3>
                        <span>Web Developer</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team/4.jpg" alt="team">

                        <div class="social">
                            <a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-instagram"></i></a>
                        </div>
                    </div>

                    <div class="content">
                        <h3>Sarah Taylor</h3>
                        <span>Desginer</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Team Area -->

<app-feedback></app-feedback>

<app-subscribe></app-subscribe>
