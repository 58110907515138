<!-- Start Subscribe Area -->
<section class="subscribe-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="newsletter-content">
                    <h2>Signup to the free newsletter</h2>

                    <form class="newsletter-form" data-bs-toggle="validator">
                        <input type="email" class="input-newsletter" placeholder="Enter your email address" name="EMAIL" required autocomplete="off">
                        <button type="submit">Subscribe <i class="flaticon-paper-plane"></i></button>
                        <div id="validator-newsletter" class="form-result"></div>
                    </form>
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="subscribe-contact-info">
                    <img src="assets/img/woman.png" alt="image">

                    <div class="content">
                        <h2>Have any questions?</h2>
                        <span>Call: <a href="tel:+440458954">+440458954</a></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Subscribe Area -->
