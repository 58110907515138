<!-- Start Footer Area -->
<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="logo">
                        <a href=""><img src="assets/img/white-logo.png" alt="image"></a>
                        <p>GPUSPEED พร้อมให้บริการพลังการประมวลผลสำหรับคุณ ด้วยเทคโลยีล้ำสมัย ประหยัดค่าใช้จ่าย</p>
                    </div>

                    <ul class="social">
                        <li><a href="" target="_blank"><i class="flaticon-facebook-letter-logo"></i></a></li>
                        <li><a href="" target="_blank"><i class="flaticon-twitter-black-shape"></i></a></li>
                        <li><a href="" target="_blank"><i class="flaticon-instagram-logo"></i></a></li>
                        <li><a href="" target="_blank"><i class="flaticon-youtube"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Resources</h3>

                    <ul class="footer-services-list">
                        <li><a href="">Documents</a></li>
                        <li><a href="">Github</a></li>
                        <li><a href="">Prices</a></li>
                        <li><a href="">FAQ</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Quick Links</h3>

                    <ul class="quick-links-list">
                        <li><a href="">About Us</a></li>
                        <li><a href="">Contact</a></li>
                        <li><a href="">Support</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Contacts</h3>

                    <ul class="footer-contact-list">
                        <li><span>Address: </span> Bangkok, Thailand</li>
                        <li><span>Email: </span> <a href=""> info&#64;gpuspeed.net</a></li>
                        <li><span>Phone: </span> <a href=""> +66 89 658 5729</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="copyright-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <p>&copy; Copyright {{currentYear}} <a href="">GPUSPEED</a>. All Rights Reserved.</p>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6">
                    <ul>
                        <li><a href="">Privacy Policy</a></li>
                        <li><a href="">Terms & Conditions</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="circle-map"><img src="assets/img/circle-map.png" alt="image"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</footer>
<!-- End Footer Area -->
<div class="go-top"><i class="fas fa-arrow-up"></i><i class="fas fa-arrow-up"></i></div>
<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-JCT0N2RQLL"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-JCT0N2RQLL');
</script>

