<!-- Solution Banner Area -->
<div class="solution-banner-area">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="solution-banner-content">
                    <h1>Customer AI & Big Data Solution for Analystics</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    
                    <ul class="banner-btn">
                        <li>
                            <a href="#" class="btn btn-primary">Get Started</a>
                        </li>
                        <li>
                            <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube">
                                <i class="fa-solid fa-play"></i>
                                Watch Intro Video
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="solution-banner-image">
                    <img src="assets/img/ai-solution/banner/main.png" alt="image">
                </div>
            </div>
        </div>
    </div>

    <div class="solution-banner-shape-1">
        <img src="assets/img/ai-solution/banner/shape1.png" alt="image">
    </div>
    <div class="solution-banner-shape-2">
        <img src="assets/img/ai-solution/banner/shape2.png" alt="image">
    </div>
</div>

<!-- Overview Features Area -->
<div class="overview-features-area pb-70">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-sm-6">
                <div class="overview-features-card">
                    <div class="features-image">
                        <img src="assets/img/ai-solution/features/features1.png" alt="image">
                    </div>
                    <div class="features-content">
                        <h3>Supply Chain</h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit nulla faucibus pulvinar iaculis et eu arcu mauris euismod duis.</p>
                        <a href="#" class="features-btn">Learn More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="overview-features-card">
                    <div class="features-image">
                        <img src="assets/img/ai-solution/features/features2.png" alt="image">
                    </div>
                    <div class="features-content">
                        <h3>Predictive Maintenance</h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit nulla faucibus pulvinar iaculis et eu arcu mauris euismod duis.</p>
                        <a href="#" class="features-btn">Learn More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="overview-features-card">
                    <div class="features-image">
                        <img src="assets/img/ai-solution/features/features3.png" alt="image">
                    </div>
                    <div class="features-content">
                        <h3>IT Optimization</h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit nulla faucibus pulvinar iaculis et eu arcu mauris euismod duis.</p>
                        <a href="#" class="features-btn">Learn More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- AI Solution Area -->
<div class="ai-solution-area pb-100">
    <div class="container">
        <div class="row justify-content-center align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="ai-solution-wrap-image">
                    <img src="assets/img/ai-solution/solution.png" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="ai-solution-content">
                    <h3>Analysis And Deep <b>Machine Learning</b> Provider With Ai Solution</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipiscing elit nulla faucibus pulvinar iaculis et eu arcu mauris euismod duis diam nunc ultrices blandit montes quis id erat diam non rhoncus cras elit.</p>
                    <ul class="list">
                        <li><i class="fa-solid fa-check"></i> The Most Personalized Object Detection</li>
                        <li><i class="fa-solid fa-check"></i> We Create The Most Realistic AI</li>
                        <li><i class="fa-solid fa-check"></i> Innovative Machine Learning Products</li>
                    </ul>
                    <div class="solution-btn">
                        <a href="#" class="btn btn-primary">Learn More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Counter Area -->
<div class="counter-area pb-100">
    <div class="container">
        <div class="counter-inner-box pt-100 pb-70">
            <div class="row justify-content-center">
                <div class="col-lg-3 col-sm-6">
                    <div class="counter-card">
                        <div class="icon">
                            <i class='bx bx-check-shield'></i>
                        </div>
                        <h3>
                            <span class="odometer" data-count="2460">2460</span>
                            <span class="sign-icon">+</span>
                        </h3>
                        <p>Completed project dolor sit amet.</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="counter-card">
                        <div class="icon">
                            <i class='bx bx-user-circle'></i>
                        </div>
                        <h3>
                            <span class="odometer" data-count="1800">1800</span>
                            <span class="sign-icon">+</span>
                        </h3>
                        <p>Satisfied clients dolor sit lorem  amet.</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="counter-card">
                        <div class="icon">
                            <i class='bx bx-layer'></i>
                        </div>
                        <h3>
                            <span class="odometer" data-count="832">832</span>
                            <span class="sign-icon">+</span>
                        </h3>
                        <p>Industries served dolor sit amet.</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="counter-card">
                        <div class="icon">
                            <i class='bx bx-buildings'></i>
                        </div>
                        <h3>
                            <span class="odometer" data-count="12">12</span>
                            <span class="sign-icon">+</span>
                        </h3>
                        <p>Office brunches around the world.</p>
                    </div>
                </div>
            </div>

            <div class="counter-shape">
                <img src="assets/img/ai-solution/counter-shape.png" alt="image">
            </div>
        </div>
    </div>
</div>

<!-- AI Services Area -->
<div class="ai-services-area ptb-100">
    <div class="container">
        <div class="section-title title-with-underline">
            <h2>See Wide Range Of Our <b>Services</b> With Artificial Intelligence</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit dolore magna aliqua.</p>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-4 col-sm-6">
                <div class="ai-services-card">
                    <div class="services-image">
                        <img src="assets/img/artificial-intelligence/services/services-1.png" alt="image">
                    </div>
                    <div class="services-content">
                        <h3>
                            <a href="#">Robotics And Drones</a>
                        </h3>
                        <p>Lorem ipsum dolor sit am adipiscing elit sed do eiusmod. Lorem ipsum dolor sit am adipiscing elit sed do eiusmod.</p>
                        <a href="#" class="services-btn">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="ai-services-card">
                    <div class="services-image">
                        <img src="assets/img/artificial-intelligence/services/services-2.png" alt="image">
                    </div>
                    <div class="services-content">
                        <h3>
                            <a href="#">Machine Learning</a>
                        </h3>
                        <p>Lorem ipsum dolor sit am adipiscing elit sed do eiusmod. Lorem ipsum dolor sit am adipiscing elit sed do eiusmod.</p>
                        <a href="#" class="services-btn">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="ai-services-card">
                    <div class="services-image">
                        <img src="assets/img/artificial-intelligence/services/services-3.png" alt="image">
                    </div>
                    <div class="services-content">
                        <h3>
                            <a href="#">Image Processing</a>
                        </h3>
                        <p>Lorem ipsum dolor sit am adipiscing elit sed do eiusmod. Lorem ipsum dolor sit am adipiscing elit sed do eiusmod.</p>
                        <a href="#" class="services-btn">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="ai-services-card">
                    <div class="services-image">
                        <img src="assets/img/artificial-intelligence/services/services-4.png" alt="image">
                    </div>
                    <div class="services-content">
                        <h3>
                            <a href="#">Virtual Reality</a>
                        </h3>
                        <p>Lorem ipsum dolor sit am adipiscing elit sed do eiusmod. Lorem ipsum dolor sit am adipiscing elit sed do eiusmod.</p>
                        <a href="#" class="services-btn">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="ai-services-card">
                    <div class="services-image">
                        <img src="assets/img/artificial-intelligence/services/services-5.png" alt="image">
                    </div>
                    <div class="services-content">
                        <h3>
                            <a href="#">AI For Cloud Services</a>
                        </h3>
                        <p>Lorem ipsum dolor sit am adipiscing elit sed do eiusmod. Lorem ipsum dolor sit am adipiscing elit sed do eiusmod.</p>
                        <a href="#" class="services-btn">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="ai-services-card">
                    <div class="services-image">
                        <img src="assets/img/artificial-intelligence/services/services-6.png" alt="image">
                    </div>
                    <div class="services-content">
                        <h3>
                            <a href="#">Deep Learning</a>
                        </h3>
                        <p>Lorem ipsum dolor sit am adipiscing elit sed do eiusmod. Lorem ipsum dolor sit am adipiscing elit sed do eiusmod.</p>
                        <a href="#" class="services-btn">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="view-all-services-btn">
                    <a href="services-1.html" class="btn btn-primary">View All Services</a>
                </div>
            </div>
        </div>
    </div>

    <div class="ai-services-shape">
        <img src="assets/img/artificial-intelligence/services/shape.png" alt="image">
    </div>
</div>

<!-- Handle Area -->
<div class="handle-area ptb-100">
    <div class="container">
        <div class="row justify-content-center align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="ai-handle-content">
                    <h3>Do What Matters And We'll <b>Handle</b> The Back Office Routine.</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipiscing elit nulla faucibus pulvinar iaculis et eu arcu mauris euismod duis diam nunc ultrices blandit montes quis id erat diam non rhoncus cras elit.</p>
                    
                    <div class="handle-inner-card">
                        <div class="content">
                            <div class="icon">
                                <i class='bx bx-dollar-circle'></i>
                            </div>
                            <h4>Fast Cost Saving</h4>
                            <p>Lorem ipsum dolor sit am adipisc elit sed do eiusmod. Demis dolor seit adipiscing elit.</p>
                        </div>
                    </div>

                    <div class="handle-inner-card">
                        <div class="content">
                            <div class="icon">
                                <i class='bx bx-layer'></i>
                            </div>
                            <h4>Critical Infrastructure</h4>
                            <p>Lorem ipsum dolor sit am adipisc elit sed do eiusmod. Demis dolor seit adipiscing elit.</p>
                        </div>
                    </div>

                    <div class="handle-btn">
                        <a href="#" class="btn btn-primary">Learn More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="ai-handle-wrap-image">
                    <img src="assets/img/ai-solution/handle.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Clients Review Area -->
<div class="clients-review-area with-linear-gradient-color ptb-100">
    <div class="container">
        <div class="section-title title-with-underline">
            <h2>Check What Our Clients <b>Feedback</b> On Our AI & Big Data Services</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit dolore magna aliqua.</p>
        </div>
        
        <div class="clients-review-slides owl-carousel owl-theme">
            <div class="clients-review-item">
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
                <p>Lorem ipsum dolor sit amet consectetur adipiscing elit dui arcu purus nec morbi pretium molestie libero quis id morbi venenatis mi lorem ipsum dolor sit amet consectetur adipiscing elit.</p>

                <div class="client-info">
                    <img src="assets/img/artificial-intelligence/user1.jpg" alt="image">
                    <h3>Thomas Adbadon</h3>
                    <p>Founder & CEO</p>
                </div>
            </div>

            <div class="clients-review-item">
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
                <p>Lorem ipsum dolor sit amet consectetur adipiscing elit dui arcu purus nec morbi pretium molestie libero quis id morbi venenatis mi lorem ipsum dolor sit amet consectetur adipiscing elit.</p>

                <div class="client-info">
                    <img src="assets/img/artificial-intelligence/user2.jpg" alt="image">
                    <h3>Steven Smith</h3>
                    <p>Developer</p>
                </div>
            </div>

            <div class="clients-review-item">
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
                <p>Lorem ipsum dolor sit amet consectetur adipiscing elit dui arcu purus nec morbi pretium molestie libero quis id morbi venenatis mi lorem ipsum dolor sit amet consectetur adipiscing elit.</p>

                <div class="client-info">
                    <img src="assets/img/artificial-intelligence/user2.jpg" alt="image">
                    <h3>Sarah Lucy</h3>
                    <p>Designer</p>
                </div>
            </div>

            <div class="clients-review-item">
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
                <p>Lorem ipsum dolor sit amet consectetur adipiscing elit dui arcu purus nec morbi pretium molestie libero quis id morbi venenatis mi lorem ipsum dolor sit amet consectetur adipiscing elit.</p>

                <div class="client-info">
                    <img src="assets/img/artificial-intelligence/user1.jpg" alt="image">
                    <h3>Thomas Adbadon</h3>
                    <p>Founder & CEO</p>
                </div>
            </div>

            <div class="clients-review-item">
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
                <p>Lorem ipsum dolor sit amet consectetur adipiscing elit dui arcu purus nec morbi pretium molestie libero quis id morbi venenatis mi lorem ipsum dolor sit amet consectetur adipiscing elit.</p>

                <div class="client-info">
                    <img src="assets/img/artificial-intelligence/user2.jpg" alt="image">
                    <h3>Steven Smith</h3>
                    <p>Developer</p>
                </div>
            </div>

            <div class="clients-review-item">
                <ul class="rating">
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                    <li><i class='bx bxs-star'></i></li>
                </ul>
                <p>Lorem ipsum dolor sit amet consectetur adipiscing elit dui arcu purus nec morbi pretium molestie libero quis id morbi venenatis mi lorem ipsum dolor sit amet consectetur adipiscing elit.</p>

                <div class="client-info">
                    <img src="assets/img/artificial-intelligence/user2.jpg" alt="image">
                    <h3>Sarah Lucy</h3>
                    <p>Designer</p>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Industries Area -->
<div class="industries-area with-bg-color pt-100 pb-70">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-3 col-md-12">
                <div class="industries-section-content">
                    <h3>Wide Range Of <b>Industries</b></h3>
                    <p>Lorem ipsum dolor sit am adipisc elit sed do eiusmod. Demis dolor seit.</p>
                    <div class="industries-btn">
                        <a href="#" class="btn btn-primary">Learn More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-9 col-md-12">
                <div class="row">
                    <div class="col-lg-4 col-sm-6">
                        <div class="industries-card">
                            <div class="content">
                                <img src="assets/img/artificial-intelligence/industries/industries-1.png" alt="image">
                                <h3>Machine Learning</h3>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6">
                        <div class="industries-card">
                            <div class="content">
                                <img src="assets/img/artificial-intelligence/industries/industries-7.png" alt="image">
                                <h3>Manufacturing</h3>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6">
                        <div class="industries-card">
                            <div class="content">
                                <img src="assets/img/artificial-intelligence/industries/industries-8.png" alt="image">
                                <h3>Healthcare</h3>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6">
                        <div class="industries-card">
                            <div class="content">
                                <img src="assets/img/artificial-intelligence/industries/industries-2.png" alt="image">
                                <h3>Robotics And Drones</h3>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6">
                        <div class="industries-card">
                            <div class="content">
                                <img src="assets/img/artificial-intelligence/industries/industries-3.png" alt="image">
                                <h3>Image Processing</h3>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6">
                        <div class="industries-card">
                            <div class="content">
                                <img src="assets/img/artificial-intelligence/industries/industries-4.png" alt="image">
                                <h3>AI For Cloud Service</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- AI Blog Area -->
<div class="ai-blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title title-with-underline">
            <h2>Latest Blog and News Don’t Miss Any <b>Updates</b> And News!</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit dolore magna aliqua.</p>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="ai-blog-card">
                    <div class="entry-thumbnail">
                        <a href="single-blog.html"><img src="assets/img/artificial-intelligence/blog/blog-1.jpg" alt="image"></a>
                    </div>
                    <div class="entry-post-content">
                        <div class="tag">
                            <a href="single-blog.html">TECHNOLOGY</a>
                        </div>
                        <h3><a href="single-blog.html">Making Peace With The Feast Or Famine Of Freelancing</a></h3>
                        <ul class="entry-meta">
                            <li>By <a href="blog-1.html">Thomas Kelbin</a></li>
                            <li>17th January</li>
                            <li>0 Comment</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="ai-blog-card">
                    <div class="entry-thumbnail">
                        <a href="single-blog.html"><img src="assets/img/artificial-intelligence/blog/blog-2.jpg" alt="image"></a>
                    </div>
                    <div class="entry-post-content">
                        <div class="tag">
                            <a href="single-blog.html">TECHNOLOGY</a>
                        </div>
                        <h3><a href="single-blog.html">I Used The Web For A Day On A 50 MB Budget</a></h3>
                        <ul class="entry-meta">
                            <li>By <a href="blog-1.html">Thomas Kelbin</a></li>
                            <li>17th January</li>
                            <li>0 Comment</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="ai-blog-card">
                    <div class="entry-thumbnail">
                        <a href="single-blog.html"><img src="assets/img/artificial-intelligence/blog/blog-3.jpg" alt="image"></a>
                    </div>
                    <div class="entry-post-content">
                        <div class="tag">
                            <a href="single-blog.html">TECHNOLOGY</a>
                        </div>
                        <h3><a href="single-blog.html">Here Are The 5 Most Telling Signs Of Micromanagement</a></h3>
                        <ul class="entry-meta">
                            <li>By <a href="blog-1.html">Thomas Kelbin</a></li>
                            <li>17th January</li>
                            <li>0 Comment</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>  
</div>

<!-- AI Subscribe Area -->
<div class="ai-subscribe-area ptb-100">
    <div class="container">
        <div class="ai-subscribe-content">
            <h2>Get The Best Blog <b>Stories</b> Into Your Inbox!</h2>

            <form class="newsletter-form" data-toggle="validator">
                <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL" required autocomplete="off">

                <button type="submit">Subscribe</button>
                <div id="validator-newsletter" class="form-result"></div>

                <div class="newsletter-checkbox-btn">
                    <input class="inp-cbx" id="cbx" type="checkbox">
                    <label class="cbx" for="cbx">
                        <span>
                            <svg width="12px" height="10px" viewbox="0 0 12 10">
                                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                            </svg>
                        </span>
                        <span>I agree submit data is being collected store</span>
                    </label>
                </div>
            </form>
        </div>
    </div>

    <div class="ai-subscribe-shape">
        <img src="assets/img/artificial-intelligence/subscribe-shape.png" alt="image">
    </div>
</div>