<!-- Start Navbar Area -->
<div class="navbar-area">
    <div class="primple-nav">
        <div class="{{styleContainer}}">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img src="assets/img/white-logo.png" alt="logo">
                </a>

                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">

                        <li class="nav-item" routerLinkActive="active" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                            <a routerLink="/" class="nav-link">Home</a>
                        </li>

                        <li class="nav-item dropdown">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">
                                Services <i class="fas fa-chevron-down"></i>
                            </a>
                            <ul class="dropdown-menu">
                                <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                    <a routerLink="/services" class="nav-link" routerLinkActive="active">Services</a>
                                </li>

                                <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                    <a routerLink="/service-details" class="nav-link" routerLinkActive="active">Service Details</a>
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                            <a routerLink="/features" class="nav-link" routerLinkActive="active">Features</a>
                        </li>

                        <li class="nav-item" routerLinkActive="active" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                            <a routerLink="/about" class="nav-link">About Us</a>
                        </li>
                        
                        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                            <a routerLink="/contact" class="nav-link" routerLinkActive="active">Contact</a>
                        </li>
                    </ul>

                    <div class="others-options" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                        <div class="option-item">
                            <i class="search-btn flaticon-search"></i>
                            <i class="close-btn fas fa-times"></i>

                            <div class="search-overlay search-popup">
                                <div class='search-box'>
                                    <form class="search-form">
                                        <input class="search-input" name="search" placeholder="Search" type="text">

                                        <button class="search-button" type="submit"><i class="fas fa-search"></i></button>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <a (click)="openExternalLink()" target="_blank" rel="noopener noreferrer" class="btn btn-primary">APP</a>                        

                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>
<!-- End Navbar Area -->
