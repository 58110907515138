<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Features</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Features</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Features Area -->
<section class="features-area ptb-110">
    <div class="container">
        <div class="overview-box">
            <div class="image wow zoomIn" data-wow-delay="1s">
                <img src="assets/img/1.png" alt="image">
            </div>

            <div class="content">
                <h3>Make your business more competitive and sustainable.</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                <ul class="features-list">
                    <li><span><i class='bx bx-check-double'></i> Influencing Sales</span></li>
                    <li><span><i class='bx bx-check-double'></i> Generating Insights</span></li>
                    <li><span><i class='bx bx-check-double'></i> Engaging Customer</span></li>
                    <li><span><i class='bx bx-check-double'></i> Boosting Operations</span></li>
                </ul>
            </div>
        </div>

        <div class="overview-box">
            <div class="content">
                <h3>Do what matters and we'll handle the back office routine.</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                <ul class="features-list">
                    <li><span><i class='bx bx-check-double'></i> Fully Support</span></li>
                    <li><span><i class='bx bx-check-double'></i> Reliable & Cost Effective</span></li>
                    <li><span><i class='bx bx-check-double'></i> Dedicated Developers</span></li>
                    <li><span><i class='bx bx-check-double'></i> 100% Customer Satisfaction</span></li>
                </ul>
            </div>

            <div class="image wow zoomIn" data-wow-delay="1s">
                <img src="assets/img/2.png" alt="image">
            </div>
        </div>
    </div>

    <div class="rectangle-shape1"><img src="assets/img/rectangle-shape1.png" alt="image"></div>
    <div class="rectangle-shape2"><img src="assets/img/rectangle-shape2.png" alt="image"></div>
</section>
<!-- End Features Area -->

<app-funfact></app-funfact>

<app-subscribe></app-subscribe>
